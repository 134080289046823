import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const BrokerContact = ( props ) => (
  <Layout  logo={props.pageContext.broker_logo}>
    <SEO title="Broker2" />
    <h1>Broker Page2</h1>
    <p>Welcome to the broker subpage</p>
    <Link to="/">Go back to the homepage</Link>
    <Button>Something</Button>
    <Alert variant="success">
        <Alert.Heading>Hey, nice to see you</Alert.Heading>
        <p>
            Aww yeah, you successfully read this important alert message. This example
            text is going to run a bit longer so that you can see how spacing within an
            alert works with this kind of content.
        </p>
        <hr />
        <p className="mb-0">
            Whenever you need to, be sure to use margin utilities to keep things nice
            and tidy.
        </p>
        </Alert>
  </Layout>
)

export default BrokerContact
